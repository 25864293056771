<script>
import {
  ArrowUpIcon,
  CheckIcon,
  MapPinIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from "vue-feather-icons";

import Navbar from "@/view/front/components/navbar";
import Switcher from "@/view/front/components/switcher";
import Footer from "@/view/front/components/footer";
import axios from "axios";
import Swal from "sweetalert2";
import { API_URL, MEDIA_URL } from "@/core/api";
import Skeleton from "@/view/front/components/skeleton";
import IndexService from "@/MainServices/IndexService.js";
import Multiselect from "vue-multiselect";
import moment from "moment";
/**
 * page-jobs-search component
 */
export default {
  data() {
    return {
      myCitiesArray: [],
      auth: localStorage.getItem("id_token") !== null ? true : false,
      token: `Bearer ${localStorage.getItem("token")}`,
      loading: true,
      mediaUrl: MEDIA_URL,
      apiUrl: API_URL,
      data: [],
      minSalaryValue: 0,
      min_min: null,
      min_max: null,
      max_min: null,
      max_max: null,
      maxSalaryValue: 0,
      search_text:
        typeof this.$router.history.current.params.search_text !== undefined
          ? this.$router.history.current.params.search_text
          : "",
      selected_cities:
        typeof this.$router.history.current.params.selected_cities !==
        "undefined"
          ? this.$router.history.current.params.selected_cities
          : [],
      description_search:
        this.$router.history.current.params.description_search,

      cities: [],
      originalData: [],
      show_load_more: true,
      page_num: 1,

      jobFunctions: [],
      jobTypes: [],
      jobShifts: [],
      experiences: [],
      jobIndustries: [],
      viewsData: null,
      selected_job_functions: [],
      selected_job_type: [],
      selected_job_shift: [],
      selected_job_experience: [],
      selected_job_industry: [],
      salary_range: [],
      city: [],
      desiredSalaryOptions: [],
      detail: null,
      date_posted: "Anytime",
      datePostedItems: [],
      posted_by: "Anyone",
      postedByItems: [],
      saveJobSuccess1: "",
      applied: [],
      saveJobSuccess: {},
      savejobbtnclicked: [],
      classToggle: false,
      spinner: false,
    };
  },
  components: {
    Navbar,
    Switcher,
    Footer,
    ArrowUpIcon,
    CheckIcon,
    MapPinIcon,
    Skeleton,
    Multiselect,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon,
  },
  mounted() {
    if (Object.keys(this.$router.history.current.params).length !== 0) {
      IndexService.getCities().then((res) => {
        res.data.map((el) => {
          this.cities.push({
            id: el.id,
            value: el.city_name,
          });
        });
      });
      this.search();
    } else {
      this.getResults();
      IndexService.getCities().then((res) => {
        res.data.map((el) => {
          this.cities.push({
            id: el.id,
            value: el.city_name,
          });
        });
        var s = [
          "Upto 20,000",
          "20,001 - 40,000",
          "40,001 - 60,000",
          "60,001 - 80,000",
          "80,001 - 100,000",
          "100,001 - 125,000",
          "125,001 - 150,000",
          "150,001 - 175,000",
          "175,001 - 225,000",
          "225,001 - 275,000",
          "275,001 - 300,000",
          "300,000 - 350,000",
          "350,000 +",
        ];
        s.map((el, i) => {
          this.desiredSalaryOptions.push({
            id: i,
            value: el,
          });
        });
        this.desiredSalaryOptions.unshift(
          ...[{ id: "null", value: "Any salary" }]
        );
      });
    }
    this.getResults(this.token);
    this.getViewsData();
    this.getJobFunctions();
    this.getJobTypes();
    this.getJobShifts();
    this.getJobCareerLevel();
    this.getJobIndustries();
    this.getMinSalary();
  },
  methods: {
    moment: function (date) {
      return moment(date);
    },
    getMinSalary() {
      axios
        .get(`${API_URL}/job_Application`)
        .then((res) => {
          // console.log("res:", res.data);
          // this.minSR = res.data;
          // console.log('JOBS' , this.minSR);
          this.min_min = res.data["min_min"];
          this.min_max = res.data["min_max"];
          this.max_min = res.data["max_min"];
          this.max_max = res.data["max_max"];
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getResults(token) {
      this.loading = true;
      axios
        .get(`${this.apiUrl}/get-jobs?page=${this.page_num}`)
        .then((response) => {
          this.data = [...this.data, ...response.data.data];
          this.originalData = [...this.originalData, ...response.data.data];
          this.loading = false;
          if (response.data.next_page_url == null) {
            this.show_load_more = false;
          }
          const saveJobSuccess = this.saveJobSuccess;
          $.each(this.data, function (index, item) {
            axios.defaults.headers.common["Authorization"] = token;
            axios
              .post(`${API_URL}/already-save-job`, {
                job_id: item.id,
              })
              .then((response) => {
                // console.log(response);
                saveJobSuccess[item.id] = response.data.msg;
              });
          });
          const applied = this.applied;
          $.each(this.data, function (index, item) {
            axios.defaults.headers.common["Authorization"] = token;
            axios
              .post(`${API_URL}/save-check-applied-job`, {
                job_id: item.id,
              })
              .then((res) => {
                // console.log("Umer applied", applied);
                if (res.data == true) {
                  applied[item.id] = true;
                } else {
                  applied[item.id] = false;
                }
              });
          });
          IndexService.getCities().then((res) => {
            res.data.map((el) => {
              this.cities.push({
                id: el.id,
                value: el.city_name,
              });
            });
            var s = [
              "Upto 20,000",
              "20,001 - 40,000",
              "40,001 - 60,000",
              "60,001 - 80,000",
              "80,001 - 100,000",
              "100,001 - 125,000",
              "125,001 - 150,000",
              "150,001 - 175,000",
              "175,001 - 225,000",
              "225,001 - 275,000",
              "275,001 - 300,000",
              "300,000 - 350,000",
              "350,000 +",
            ];
            s.map((el, i) => {
              this.desiredSalaryOptions.push({
                id: i,
                value: el,
              });
            });
            this.desiredSalaryOptions.unshift(
              ...[{ id: "null", value: "Any salary" }]
            );
          });
        });
    },

    goToCity(item) {
      this.$router.replace({
        path: "/jobs/Pakistan/" + item,
        params: {
          search_text: this.search_text,
          selected_cities: [{ id: 1, value: item }],
        },
      });
    },

    makeTitle(slug) {
      var words = slug.split("-");
      for (var i = 0; i < words.length; i++) {
        var word = words[i];
        words[i] = word.charAt(0).toUpperCase() + word.slice(1);
      }
      return words.join(" ").replace("  ", " & ");
    },

    changeSelect(n) {
      this.data = this.originalData;
      if (n.target.value === "all") {
        this.originalData = this.data;
        return;
      }
      var temp = [];
      this.data.forEach((element) => {
        if (element.job_type.indexOf(n.target.value) !== -1) {
          temp.push(element);
        }
      });
      this.data = temp;
    },
    loadmore() {
      this.page_num = this.page_num + 1;
      this.getResults();
    },

    getJobFunctions() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-sectors`).then((response) => {
        this.jobFunctions = response.data;
      });
    },
    getJobTypes() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-types`).then((response) => {
        this.jobTypes = response.data;
      });
    },
    getJobShifts() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-shifts`).then((response) => {
        this.jobShifts = response.data;
      });
    },
    getJobCareerLevel() {
      axios.get(`${this.apiUrl}/get-job-career-levels`).then((res) => {
        res.data.map((c) => {
          if (c.career_level === "Executive & management") {
            c.career_level = "Executive";
          }

          if (c.career_level === "Experienced professional") {
            c.career_level = "Experienced";
          }

          this.experiences.push(c);
        });
      });
    },
    getJobIndustries() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-job-industries`).then((response) => {
        this.jobIndustries = response.data;
      });
    },
    getViewsData() {
      this.loading = true;
      axios.get(`${this.apiUrl}/get-views-data`).then((response) => {
        this.datePostedItems = [
          { title: "Anytime", value: "Anytime", days: "Anytime" },
          { title: "Today", value: response.data.today_job_count, days: 1 },
          {
            title: "Last Three Days",
            value: response.data.last_three_days_job_count,
            days: 3,
          },
          {
            title: "Last Week",
            value: response.data.last_week_job_count,
            days: 7,
          },
          {
            title: "Last Two Weeks",
            value: response.data.last_two_weeks_job_count,
            days: 14,
          },
        ];
        this.postedByItems = [
          { title: "Anyone", value: "Anyone", postedBy: "Anyone" },
          {
            title: "Recruitment Agencies",
            value: response.data.posted_by_agency,
            postedBy: "Agency",
          },
          {
            title: "Direct Employers",
            value: response.data.posted_by_employer,
            postedBy: "Direct Employer",
          },
          {
            title: "job4u.pk Network",
            value: response.data.posted_by_job4u,
            postedBy: "Data admin",
          },
        ];
      });
    },
    filterSearch() {
      var check = false;
      this.salary_range.forEach((element) => {
        if (element.value == "Any salary") {
          check = true;
        }
      });
      axios
        .post(`${this.apiUrl}/filter-search-jobs`, {
          job_functions: this.selected_job_functions,
          job_type: this.selected_job_type,
          job_shift: this.selected_job_shift,
          salary_range: this.salary_range,
          min_salary: this.minSalaryValue,
          max_salary: this.maxSalaryValue,
          city: this.city,
          experience: this.selected_job_experience,
          salary_range_any: check,
          date_posted: this.date_posted,
          posted_by: this.posted_by,
        })
        .then((response) => {
          this.data = response.data;
          this.loading = false;
          if (this.data.length < 12) {
            this.show_load_more = false;
          } else {
            this.show_load_more = true;
          }
        });
    },
    clearFilter() {
      this.search();
    },
    search() {
      this.spinner = !this.spinner;
      axios
        .post(`${this.apiUrl}/search-jobs`, {
          search_text: this.search_text,
          selected_cities: this.selected_cities,
          description_search: this.description_search,
          page_num: this.page_num,
        })
        .then((response) => {
          this.spinner = !this.spinner;
          console.log("RES", response.data);
          // this.myCitiesArray = null

          console.log("myCitiesArray =>", this.myCitiesArray);

          if (this.city.length > 0) {
            this.myCitiesArray = [];
            this.city.map((c) => {
              this.myCitiesArray.push(c.value);
            });
            // filter by cities
            this.data = response.data.filter((job) =>
              job.location_city.some((r) => this.myCitiesArray.includes(r))
            );
          } else {
            this.data = response.data;
          }
          this.loading = false;
          if (this.data.length < 12) {
            this.show_load_more = false;
          } else {
            this.show_load_more = true;
          }
        });
    },
    _jobFunctionSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_functions.includes(event.target.value)) {
          var temp = this.selected_job_functions;
          temp.push(event.target.value);
          this.selected_job_functions = temp;
        }
      } else {
        var temp = [];
        this.selected_job_functions.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_functions = temp;
      }
      // console.log(this.selected_job_functions);
    },
    _jobTypeSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_type.includes(event.target.value)) {
          var temp = this.selected_job_type;
          temp.push(event.target.value);
          this.selected_job_type = temp;
        }
      } else {
        var temp = [];
        this.selected_job_type.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_type = temp;
      }
      // console.log(this.selected_job_type);
    },
    _jobShiftSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_shift.includes(event.target.value)) {
          var temp = this.selected_job_shift;
          temp.push(event.target.value);
          this.selected_job_shift = temp;
        }
      } else {
        var temp = [];
        this.selected_job_shift.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_shift = temp;
      }
      // console.log(this.selected_job_shift);
    },
    _jobIndustrySelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_industry.includes(event.target.value)) {
          var temp = this.selected_job_industry;
          temp.push(event.target.value);
          this.selected_job_industry = temp;
        }
      } else {
        var temp = [];
        this.selected_job_industry.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_industry = temp;
      }
    },
    _jobCareerSelection(event) {
      if (event.target.checked) {
        if (!this.selected_job_experience.includes(event.target.value)) {
          var temp = this.selected_job_experience;
          temp.push(event.target.value);
          this.selected_job_experience = temp;
        }
      } else {
        var temp = [];
        this.selected_job_experience.forEach((element) => {
          if (element !== event.target.value) {
            temp.push(event.target.value);
          }
        });
        this.selected_job_experience = temp;
      }
    },
    singleDetail(item) {
      this.detail = item;
    },
    saveJob(item) {
      // this.savejobbtnclicked[item.id] = response.data.msg;
      axios.defaults.headers.common["Authorization"] = this.token;
      axios
        .post(`${API_URL}/save-job`, {
          job_id: item.id,
        })
        .then((response) => {
          this.saveJobSuccess[item.id] = response.data.msg;
          this.savejobbtnclicked[item.id] = response.data.msg;
          setTimeout(() => {
            this.savejobbtnclicked[item.id] = "";
          }, 3000);
          if (response.data.msg === "Job has been saved") {
            Swal.fire({
              title: "",
              text: "Job has been saved.",
              icon: "success",
              confirmButtonClass: "btn btn-secondary",
              heightAuto: false,
            });
          } else {
            if (response.data.msg === "Job already saved") {
              Swal.fire({
                title: "",
                text: "Job already saved.",
                icon: "success",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: false,
              });
            }
          }
        });
    },
    ToggleSearch() {
      this.classToggle = !this.classToggle;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<template>
  <div>
    <Navbar />
    <Skeleton v-if="loading" class="pt-20" />
    <button type="button" @click="loadmore" v-if="show_load_more"></button>
    <!-- Shape Start -->
    <div class="position-relative">
      <div class="shape overflow-hidden text-white"></div>
    </div>
    <!--Shape End-->

    <!-- Job List Start -->
    <section class="section pt-18">
      <div class="m-2">
        <!-- mobile Search -->
        <div class="row hide_by_ch">
          <div class="col-lg-12 col-12">
            <div class="job_lit_heading">Jobs in Pakistan</div>
          </div>
          <div class="col-lg-12">
            <div class="input-group stylish-input-group3_ch">
              <input
                type="text"
                class="form-control serach_i_ch"
                placeholder="Search"
                v-model="search_text"
                @keyup.enter="search()"
                style="padding: 10px 10px 10px 31px !important"
              />
              <img
                data-v-07e2cee6=""
                src="/images/s_1.svg"
                width="15"
                height="15"
                alt="..."
                class="icon_search__"
              />
            </div>
            <!-- old search -->
            <!-- <div class="input-group stylish-input-group3_ch mt-4">
              <input
                type="text"
                class="form-control serach_i_ch"
                placeholder="Location"
                v-model="city"
                @keyup.enter="search()"
              />
            </div> -->

            <!-- new search -->
            <div class="card mt-3">
              <!-- <div class="card-headerch filter_headingch m_title">
                Location
              </div> -->
              <div
                class="card-body new_multislect"
                style="padding: 1px 7px 0px 33px !important"
              >
                <img
                  data-v-07e2cee6=""
                  src="/images/s_2.svg"
                  width="15"
                  height="15"
                  alt="..."
                  class="icon_search__"
                />
                <multiselect
                  id="multiselectCities"
                  deselect-label=""
                  select-label=""
                  v-model="city"
                  :height="300"
                  class="p-0 pr-4"
                  track-by="value"
                  :options="cities"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="City"
                  label="value"
                  :preselect-first="false"
                >
                </multiselect>
              </div>
            </div>

            <button
              @click="search()"
              :class="{ spinner: spinner === true }"
              class="job_btn_serh_list"
            >
              Search
            </button>
          </div>
          <button @click="ToggleSearch()" class="search__ch_btn">
            Advanced search
          </button>
        </div>
        <div class="row" style="margin-right: -15.5px">
          <div class="col-12">
            <div class="border-bottom">
              <div class="row">
                <div
                  class="col-lg-2 col-md-3 left_side_filer left_side_mobile"
                  :class="{ ToggleClass_ch: classToggle === true }"
                >
                  <!-- old salary range -->
                  <!-- <div class="card">
                    <div>
                      <div class="card-headerch filter_headingch m_title">
                        SALARY RANGEs
                      </div>
                    </div>
                    <div class="card-body new_multislect">
                      <multiselect
                        id="multiselectSalary"
                        deselect-label=""
                        select-label=""
                        v-model="salary_range"
                        :height="300"
                        :options="desiredSalaryOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Salary Range"
                        label="value"
                        track-by="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div> -->

                  <!-- new salary range -->
                  <div class="card">
                    <div>
                      <div class="card-headerch filter_headingch m_title">
                        SALARY RANGE
                      </div>
                      <div>
                        <!-- <Slider class="slider-blue" v-model="example1.value"  v-bind="example1" /> -->

                        <div class="form-group px-4">
                          <label for="formControlRange"
                            >min salary {{ this.minSalaryValue }}
                          </label>
                          <input
                            type="range"
                            v-bind:min="min_min"
                            v-bind:max="min_max"
                            v-model="minSalaryValue"
                            class="form-control-range"
                            id="formControlRange"
                          />
                        </div>
                        <div class="form-group px-4">
                          <label for="formControlRange"
                            >max salary {{ this.maxSalaryValue }}
                          </label>
                          <input
                            type="range"
                            v-bind:min="max_min"
                            v-bind:max="max_max"
                            v-model="maxSalaryValue"
                            class="form-control-range"
                            id="formControlRange"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="card-body new_multislect d-none">
                      <multiselect
                        id="multiselectSalary"
                        deselect-label=""
                        select-label=""
                        v-model="salary_range"
                        :height="300"
                        :options="desiredSalaryOptions"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="Salary Range"
                        label="value"
                        track-by="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <!--  -->
                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      Location
                    </div>
                    <div class="card-body new_multislect">
                      <multiselect
                        id="multiselectCities"
                        deselect-label=""
                        select-label=""
                        v-model="city"
                        :height="300"
                        track-by="value"
                        :options="cities"
                        :multiple="true"
                        :close-on-select="false"
                        :clear-on-select="false"
                        :preserve-search="true"
                        placeholder="City"
                        label="value"
                        :preselect-first="false"
                      >
                      </multiselect>
                    </div>
                  </div>
                  <div class="display-flex-on-mbl">
                    <div class="card">
                      <div class="card-headerch filter_headingch m_title">
                        Date Posted
                      </div>
                      <div class="card-body new_multislect pb-3">
                        <select
                          v-model="date_posted"
                          class="
                            selectch
                            form-control
                            input_ch_h input_ch_h_new
                          "
                        >
                          <option
                            v-for="item in datePostedItems"
                            :key="item.title + '1'"
                            :value="item.days"
                          >
                            {{
                              item.value !== "Anytime"
                                ? item.title + `(${item.value})`
                                : item.title
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-headerch filter_headingch m_title">
                        Posted By
                      </div>
                      <div class="card-body new_multislect pb-3">
                        <select
                          v-model="posted_by"
                          class="
                            selectch
                            form-control
                            input_ch_h input_ch_h_new
                          "
                        >
                          <option
                            v-for="item in postedByItems"
                            :key="item.title + '2'"
                            :value="item.postedBy"
                          >
                            {{
                              item.value !== "Anyone"
                                ? item.title + `(${item.value})`
                                : item.title
                            }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      JOB FUNCTIONS
                    </div>
                    <div class="card-body new_multislect">
                      <ul class="ul_p_l display-in-grid-imb">
                        <li
                          v-for="(item, index) in jobFunctions"
                          :key="index + '3'"
                        >
                          <input
                            type="checkbox"
                            :id="item.job_function_name"
                            :name="item.job_function_name"
                            class="custom-control-input"
                            :value="item.job_function_name"
                            v-on:click="_jobFunctionSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_function_name"
                            style="font-size: 13px; text-transform: initial"
                            >{{ item.job_function_name }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      JOB TYPES
                    </div>
                    <div class="card-body new_multislect">
                      <ul class="ul_p_l display-in-grid-imb">
                        <li
                          v-for="(item, index) in jobTypes"
                          :key="index + '4'"
                        >
                          <input
                            type="checkbox"
                            :id="item.job_type"
                            :name="item.job_type"
                            class="custom-control-input"
                            :value="item.job_type"
                            v-on:click="_jobTypeSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_type"
                            style="font-size: 13px"
                            >{{ item.job_type }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      JOB SHIFTS
                    </div>
                    <div class="card-body new_multislect">
                      <ul class="ul_p_l display-in-grid-imb">
                        <li
                          v-for="(item, index) in jobShifts"
                          :key="index + '5'"
                        >
                          <input
                            type="checkbox"
                            :id="item.job_shift"
                            :name="item.job_shift"
                            class="custom-control-input"
                            :value="item.job_shift"
                            v-on:click="_jobShiftSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.job_shift"
                            style="font-size: 13px"
                            >{{ item.job_shift }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      CAREER LEVELS
                    </div>
                    <div class="card-body new_multislect">
                      <ul class="ul_p_l display-in-grid-imb">
                        <li
                          v-for="(item, index) in experiences"
                          :key="index + '6'"
                        >
                          <input
                            type="checkbox"
                            :id="item.career_level"
                            :name="item.career_level"
                            class="custom-control-input"
                            :value="item.career_level"
                            v-on:click="_jobCareerSelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.career_level"
                            style="font-size: 13px"
                            >{{ item.career_level }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-headerch filter_headingch m_title">
                      JOB INDUSTRY
                    </div>
                    <div class="card-body new_multislect">
                      <ul class="ul_p_l display-in-grid-imb">
                        <li
                          v-for="(item, index) in jobIndustries"
                          :key="index + '7'"
                        >
                          <input
                            type="checkbox"
                            :id="item.industry_name"
                            :name="item.industry_name"
                            class="custom-control-input"
                            :value="item.industry_name"
                            v-on:click="_jobIndustrySelection($event)"
                          /><label
                            class="custom-control-label"
                            :for="item.industry_name"
                            style="font-size: 13px"
                            >{{ item.industry_name }}</label
                          >
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div
                    class="card hide_by_ch"
                    id="floating_search"
                    style="position: fixed"
                  >
                    <div
                      class="card-body"
                      style="
                        display: flex;
                        justify-content: space-around;
                        padding: 1.2rem;
                        padding-left: 5% !important;
                        padding-right: 5% !important;
                      "
                    >
                      <button
                        type="button"
                        @click="filterSearch"
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px; padding: 5px"
                      >
                        Apply
                      </button>

                      <button
                        type="button"
                        @click="clearFilter"
                        class="btn btn-sm btn-light"
                        style="margin-top: 5%; padding: 5px"
                      >
                        > Clear
                      </button>
                    </div>
                  </div>
                  <!-- mobile serach btn -->
                  <div
                    class="card hide_on_dectop_ch__"
                    id="floating_search"
                    :class="{ ToggleClass_ch: classToggle === true }"
                  >
                    <div
                      class="card-body"
                      style="
                        display: flex;
                        justify-content: space-around;
                        padding: 1.2rem;
                        padding-left: 5% !important;
                        padding-right: 5% !important;
                      "
                    >
                      <button
                        type="button"
                        @click="filterSearch"
                        class="btn btn-sm btn-primary"
                        style="margin-top: 5%; bottom: 40px; padding: 5px"
                      >
                        Apply
                      </button>

                      <button
                        type="button"
                        @click="clearFilter"
                        class="btn btn-sm btn-light"
                        style="margin-top: 5%; padding: 5px"
                      >
                        > Clear
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 col-md-9 pagech1 mt-0">
                  <div class="row">
                    <div class="col-lg-12 job_listing_header hide_on_mobile_ch">
                      <div class="simple-heading">
                        Jobs in Pakistan

                        <span class="pull-right">
                          <div class="navbar-form" role="search">
                            <div class="input-group stylish-input-group">
                              <input
                                type="text"
                                class="form-control serach_i_ch"
                                placeholder="Search"
                                v-model="search_text"
                                @keyup.enter="search()"
                              />
                              <span class="input-group-addon">
                                <button type="button" class="ch_btn_submit">
                                  <span class="fa fa-search"></span>
                                </button>
                              </span>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="col-lg-12 col-md-7 col-12">
                      <div
                        v-if="data.length == 0"
                        class="text-bold blue text-center"
                        style="font-size: 24px"
                      >
                        No data found
                      </div>
                      <div
                        class="
                          col-lg-12
                          row
                          mx-0
                          col-md-4 col-12
                          pl-0
                          pr-0
                          job_list_d
                        "
                        v-for="(item, index) in data"
                        :key="index + '8'"
                        :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
                      >
                        <div
                          class="card overflow-hidden ch-box"
                          :style="
                            item.urgent == 1
                              ? 'background: #ffc6c638 !important'
                              : ''
                          "
                        >
                          <div class="position position_style_ch row">
                            <router-link
                              :to="
                                '/jobs-careers/pakistan/' +
                                item.location_city
                                  .map((v) => v.toLowerCase())
                                  .join('-') +
                                '/' +
                                item.job_title
                                  .toLowerCase()
                                  .replace(/ /g, '-')
                                  .replace(/[^\w-]+/g, '') +
                                '/' +
                                item.id
                              "
                              class="text-dark col-lg-10 col-8 title_job_by_ch"
                              >{{ item.job_title }}
                              <span class="job_function"
                                >({{ item.job_function }})</span
                              >
                            </router-link>
                            <div
                              class="col-lg-2 col-4 pr-0 Urgent_hiring"
                              style="text-align: end"
                            >
                              <img
                                v-if="item.feature == 1"
                                src="images/job_page/Featured_new.svg"
                                alt="..."
                              />
                            </div>
                            <div class="co_name col-lg-9 col-8">
                              <router-link
                                style="font-size: 15px !important"
                                :to="
                                  '/employer/' +
                                  item.recruiter.company_name
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '')
                                "
                                class="company-name"
                              >
                                {{ item.recruiter.company_name }} </router-link
                              ><span>
                                <img
                                  src="images/job_page/Verified.svg"
                                  alt="..."
                                />
                              </span>
                            </div>
                            <div
                              class="col-lg-3 col-4 pr-0 pl-0 Urgent_hiring"
                              style="text-align: end"
                            >
                              <img
                                v-if="item.urgent == 1"
                                src="/images/job_page/Urgent_hiring.svg"
                                alt="..."
                              />
                            </div>
                            <div class="col-lg-12 col-8 salary_style pl-3 pt-3">
                              <img
                                class="pr-2"
                                src="images/job_page/Money.svg"
                                alt="..."
                              />{{ item.salary_min }} -
                              {{ item.salary_max }}/Month
                            </div>
                            <div
                              class="
                                vew_details_btn
                                col-lg-3 col-4
                                pr-0
                                pl-0
                                pb-2
                                pt-2
                                hide_on_dectop_ch
                              "
                            >
                              <router-link
                                :to="
                                  '/jobs-careers/pakistan/' +
                                  item.location_city
                                    .map((v) => v.toLowerCase())
                                    .join('-') +
                                  '/' +
                                  item.job_title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '') +
                                  '/' +
                                  item.id
                                "
                                ><button>
                                  <img
                                    class="pr-1"
                                    src="images/job_page/View.png"
                                    alt="..."
                                  />
                                  View Details
                                </button></router-link
                              >
                              <!-- <button>
                                <img
                                  class="pr-1"
                                  src="images/job_page/View.png"
                                  alt="..."
                                />
                                View Details
                              </button> -->
                            </div>
                            <div class="row pb-2 mx-0 pr-0">
                              <div class="location_perant_div col-lg-9">
                                <span>
                                  <img
                                    class="pr-2 location_icon_ch"
                                    src="images/job_page/Location.svg"
                                    alt="..."
                                  />
                                </span>
                                <div
                                  @click="goToCity(_item)"
                                  class="
                                    lo_name
                                    locations_style
                                    pl-1
                                    pr-0
                                    pt-2
                                    d-flex
                                  "
                                  v-for="(_item, _index) in item.location_city"
                                  :key="_index + '9'"
                                  style="cursor: pointer"
                                >
                                  <!-- <span
                                    class="p-0"
                                    v-if="
                                      _index > 0 &&
                                      _index < item.location_city.length
                                    "
                                    style=""
                                    >&nbsp;,&nbsp;</span
                                  >
                                  {{ _item }} -->
                                  <span class="p-0 d-flex" v-if="_index < 4">
                                    <span
                                      class="p-0"
                                      v-if="
                                        _index > 0 &&
                                        _index < item.location_city.length
                                      "
                                      style=""
                                      >&nbsp;,&nbsp;</span
                                    >

                                    {{ _item }}
                                  </span>
                                  <span v-if="_index === 3" class="pt-0">
                                    , more location</span
                                  >
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div
                                class="
                                  vew_details_btn
                                  col-lg-3
                                  pr-0
                                  pb-2
                                  hide_on_mobile_ch
                                "
                                v-on:click="singleDetail(item)"
                              >
                                <button>
                                  <img
                                    class="pr-1"
                                    src="images/job_page/View.png"
                                    alt="..."
                                  />
                                  View Details
                                </button>
                              </div>
                            </div>

                            <!-- icons soical -->
                            <!-- <div class="col-lg-5"> -->
                            <!-- <div
                                class="mb-4 invite_apply"
                                style="
                                  text-align: end;
                                  font-size: 14px;
                                  font-weight: 400;
                                  display: flex;
                                  justify-content: flex-end;
                                "
                              >
                                <ul class="list-unstyled head mb-0">
                                  <li
                                    v-for="(_item, _index) in item.job_type"
                                    :key="_index"
                                    class="badge badge-success badge-pill"
                                  >
                                    {{ _item }}
                                  </li>
                                </ul>
                                <span
                                  ><i class="fas fa-user-plus ch_icon1"></i
                                  >invite</span>
                                <router-link
                                  style="padding-left: 3%"
                                  v-if="auth && !applied[item.id]"
                                  :to="
                                    '/jobs-careers/apply/pakistan/' +
                                    item.location_city
                                      .map((v) => v.toLowerCase())
                                      .join('-') +
                                    '/' +
                                    item.job_title
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  ><i class="fas fa-info-circle ch_icon1"></i
                                  >Apply</router-link
                                >

                                <router-link
                                  style="padding-left: 3%"
                                  v-if="auth && applied[item.id]"
                                  :to="
                                    '/jobs-careers/apply/pakistan/' +
                                    item.location_city
                                      .map((v) => v.toLowerCase())
                                      .join('-') +
                                    '/' +
                                    item.job_title
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  :disabled="applied"
                                  :event="!applied ? 'click' : ''"
                                  ><i
                                    class="fas fa-info-circle ch_icon1"
                                    style="color: #2eca8b !important"
                                  ></i
                                  >Applied</router-link
                                >

                                <span
                                  v-if="auth && !saveJobSuccess[item.id]"
                                  @click="saveJob(item)"
                                >
                                  <i class="fas fa-bookmark ch_icon1"></i>
                                  Save
                                </span>
                                <span v-if="auth && saveJobSuccess[item.id]">
                                  <i
                                    class="fas fa-bookmark ch_icon1"
                                    style="color: #2eca8b !important"
                                  ></i>
                                  Saved
                                </span>
                              </div> -->

                            <!-- <ul class="list-unstyled head mb-0">
                                <ul
                                  class="list-unstyled social-icon"
                                  style="text-align: right !important"
                                >
                                  <ShareNetwork
                                    network="twitter"
                                    url="https://news.vuejs.org/issues/180"
                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="job4u_pk"
                                    twitterUser="youyuxi"
                                  >
                                    <li
                                      class="list-inline-item ml-1"
                                      style="margin-right: 0%"
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                        style="border: none"
                                        title="Share on Twitter"
                                      >
                                        <twitter-icon
                                          class="fea icon-xs fea-social"
                                        ></twitter-icon>
                                      </a>
                                    </li>
                                  </ShareNetwork>

                                  <ShareNetwork
                                    network="facebook"
                                    url="https://news.vuejs.org/issues/180"
                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="job4u_pk"
                                  >
                                    <li
                                      class="list-inline-item"
                                      style="margin-right: 0%"
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                        style="border: none"
                                        title="Share on Facebook"
                                      >
                                        <facebook-icon
                                          class="fea icon-sm fea-social"
                                        ></facebook-icon>
                                      </a>
                                    </li>
                                  </ShareNetwork>
                                  <ShareNetwork
                                    network="linkedin"
                                    url="https://news.vuejs.org/issues/180"
                                    title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                    description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                    quote="The hot reload is so fast it\'s near instant. - Evan You"
                                    hashtags="job4u_pk"
                                  >
                                    <li class="list-inline-item">
                                      <a
                                        href="javascript:void(0)"
                                        class="rounded"
                                        style="border: none"
                                        title="Share on LinkedIn"
                                      >
                                        <linkedin-icon
                                          class="fea icon-sm fea-social"
                                        ></linkedin-icon>
                                      </a>
                                    </li>
                                  </ShareNetwork>
                                </ul>
                              </ul> -->
                            <!-- </div> -->
                          </div>
                          <!-- end Position -->
                          <!-- copmany icon section -->
                          <!-- <div class="row pt-3">
                            <div class="col-lg-2 pr-0" style="text-align: left">
                              <div class="sel_icon tooltipch">
                                <i class="fas fa-money-bill-alt ch_icon"></i>
                                <span class="tooltiptextch">Salary, PKR</span>
                              </div>
                              <div
                                class="sel_name"
                                style="font-size: 13px; font-weight: 500"
                              >
                                {{ item.salary_min }} - {{ item.salary_max }}
                              </div>
                            </div>
                            <div class="col-lg-3" style="text-align: left">
                              <div class="cre_icon tooltipch">
                                <i class="fas fa-user ch_icon"></i>
                                <span class="tooltiptextch">Job Function</span>
                              </div>
                              <div class="cre_name" style="font-size: 14px">
                                {{ item.job_function }}
                              </div>
                            </div>
                            <div class="col-lg-7 pl-0" style="text-align: left">
                              <div class="lo_icon tooltipch">
                                <i class="fas fa-map-marker-alt ch_icon"></i>
                                <span class="tooltiptextch">locations</span>
                              </div>
                              <div
                                @click="goToCity(_item)"
                                class="lo_name locations_style"
                                v-for="(_item, _index) in item.location_city"
                                :key="_index"
                                style="cursor: pointer"
                              >
                                {{ _item }}
                              </div>
                            </div>
                          </div> -->

                          <!--end copmany icon section -->
                          <!-- job discrption -->
                          <div
                            class="
                              col-lg-12
                              pl-0
                              pt-3
                              d-flex
                              job_description_style
                            "
                            style="max-height: 85px; overflow: hidden"
                          >
                            <p v-html="item.job_description"></p>
                          </div>
                        </div>
                        <!-- </div> -->
                      </div>
                      <div
                        class="
                          col-lg-12
                          row
                          mx-0
                          col-md-4 col-12
                          pl-0
                          pr-0
                          job_list_m
                        "
                        v-for="(item, index) in data"
                        :key="index + '12'"
                        :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
                      >
                        <div
                          class="card overflow-hidden ch-box"
                          :style="
                            item.urgent == 1
                              ? 'background: #ffc6c638 !important'
                              : ''
                          "
                        >
                          <div class="position position_style_ch row">
                            <router-link
                              :to="
                                '/jobs-careers/pakistan/' +
                                item.location_city
                                  .map((v) => v.toLowerCase())
                                  .join('-') +
                                '/' +
                                item.job_title
                                  .toLowerCase()
                                  .replace(/ /g, '-')
                                  .replace(/[^\w-]+/g, '') +
                                '/' +
                                item.id
                              "
                              class="text-dark col-lg-10 col-8 title_job_by_ch"
                              >{{ item.job_title }}
                            </router-link>
                            <div
                              class="col-lg-2 col-4 pr-0 vew_details_btn m-0"
                            >
                              <router-link
                                :to="
                                  '/jobs-careers/pakistan/' +
                                  item.location_city
                                    .map((v) => v.toLowerCase())
                                    .join('-') +
                                  '/' +
                                  item.job_title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '') +
                                  '/' +
                                  item.id
                                "
                                ><button class="py-0" style="font-weight: 400">
                                  <!-- <img
                                    class="pr-1"
                                    src="/images/job_page/View.png"
                                    alt="..."
                                  /> -->
                                  Details >>
                                </button></router-link
                              >
                              <!-- <img
                                v-if="item.feature == 1"
                                src="images/job_page/Featured_new.svg"
                                alt="..."
                              /> -->
                            </div>
                            <div class="col-8 m-0 py-0">
                              <span class="job_function">{{
                                item.job_function
                              }}</span>
                            </div>
                            <div
                              class="
                                col-4
                                pr-0
                                m-0
                                Urgent_hiring
                                vew_details_btn
                              "
                              style="text-align: end"
                            >
                              <img
                                v-if="item.feature == 1"
                                src="images/job_page/Featured_new.svg"
                                alt="..."
                              />
                            </div>
                            <div class="co_name col-lg-9 col-8">
                              <span>
                                <img
                                  src="images/job_page/Verified_m.svg"
                                  alt="..."
                                />
                              </span>
                              <router-link
                                style="font-size: 15px !important"
                                :to="
                                  '/employer/' +
                                  item.recruiter.company_name
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '')
                                "
                                class="company-name"
                              >
                                {{ item.recruiter.company_name }}
                              </router-link>
                            </div>
                            <div
                              class="col-lg-3 col-4 pr-0 pl-0 Urgent_hiring"
                              style="text-align: end"
                            >
                              <img
                                v-if="item.urgent == 1"
                                src="/images/job_page/Urgent_hiring.svg"
                                alt="..."
                              />
                            </div>
                            <div class="col-lg-12 col-8 salary_style pl-3 pt-1">
                              <img
                                class="pr-2"
                                src="images/job_page/Money.svg"
                                alt="..."
                              />{{ item.salary_min }} -
                              {{ item.salary_max }}/Month
                            </div>
                            <!-- <div
                              class="
                                vew_details_btn
                                col-lg-3 col-4
                                pr-0
                                pl-0
                                pb-2
                                pt-2
                                hide_on_dectop_ch
                              "
                            >
                              <router-link
                                :to="
                                  '/jobs-careers/pakistan/' +
                                  item.location_city
                                    .map((v) => v.toLowerCase())
                                    .join('-') +
                                  '/' +
                                  item.job_title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '')
                                "
                                ><button>
                                  <img
                                    class="pr-1"
                                    src="/images/job_page/View.png"
                                    alt="..."
                                  />
                                  View Details
                                </button></router-link
                              >
                            </div> -->
                            <div class="col-12 hide_on_dectop_ch pl-3">
                              <div class="media-body d-flex pt-2">
                                <h4
                                  class="
                                    widget-title
                                    tooltipch
                                    mb-0
                                    bold
                                    detail_heading
                                  "
                                >
                                  <img
                                    class="pr-2"
                                    src="images/job_page/Due_date.svg"
                                    alt="..."
                                  />
                                  <span class="tooltiptextch"
                                    >Application deadline</span
                                  >
                                </h4>
                                <p
                                  class="text-primary mb-0 mb-0 detail_body"
                                  style="color: #a82511 !important"
                                >
                                  {{
                                    moment(item.apply_by).format("MMMM Do YYYY")
                                  }}
                                </p>
                              </div>
                            </div>
                            <div class="row pb-2 mx-0 pr-0">
                              <div class="location_perant_div col-lg-9">
                                <span>
                                  <img
                                    class="pr-2 location_icon_ch"
                                    src="images/job_page/Location.svg"
                                    alt="..."
                                  />
                                </span>
                                <div
                                  @click="goToCity(_item)"
                                  class="
                                    lo_name
                                    locations_style
                                    pl-1
                                    pr-0
                                    pt-2
                                    d-flex
                                  "
                                  v-for="(_item, _index) in item.location_city"
                                  :key="_index + '13'"
                                  style="cursor: pointer"
                                >
                                  <span class="p-0 d-flex" v-if="_index < 4">
                                    <span
                                      class="p-0"
                                      v-if="
                                        _index > 0 &&
                                        _index < item.location_city.length
                                      "
                                      style=""
                                      >&nbsp;,&nbsp;</span
                                    >

                                    {{ _item }}
                                  </span>
                                  <span v-if="_index === 3" class="pt-0">
                                    , more location</span
                                  >
                                  <!-- </div> -->
                                </div>
                              </div>
                              <div
                                class="
                                  vew_details_btn
                                  col-lg-3
                                  pr-0
                                  pb-2
                                  hide_on_mobile_ch
                                "
                                v-on:click="singleDetail(item)"
                              >
                                <button>
                                  <img
                                    class="pr-1"
                                    src="images/job_page/View.png"
                                    alt="..."
                                  />
                                  View Details
                                </button>
                              </div>
                            </div>
                          </div>
                          <div
                            class="
                              col-lg-12
                              pl-0
                              pt-3
                              d-flex
                              job_description_style
                            "
                            style="max-height: 85px; overflow: hidden"
                          >
                            <p v-html="item.job_description"></p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="col-lg-12 col-md-4 col-12 d-none"
                        v-for="(item, index) in data"
                        :key="index + '14'"
                        :style="index > 0 ? 'margin-top: 2%' : 'margin-top: 0%'"
                      >
                        <div
                          class="card job-box rounded border-0 overflow-hidden"
                        >
                          <div class="border-bottom">
                            <div class="position-relative">
                              <img
                                :src="mediaUrl + item.recruiter.banner"
                                class="img-fluid"
                                alt=""
                                style="height: 198px; width: 100%"
                              />
                              <div class="job-overlay bg-white"></div>
                            </div>
                            <h5 class="mb-0 position">
                              <router-link
                                :to="
                                  '/jobs-careers/pakistan/' +
                                  item.location_city
                                    .map((v) => v.toLowerCase())
                                    .join('-') +
                                  '/' +
                                  item.job_title
                                    .toLowerCase()
                                    .replace(/ /g, '-')
                                    .replace(/[^\w-]+/g, '') +
                                  '/' +
                                  item.id
                                "
                                class="text-dark"
                                >{{ item.job_title }}</router-link
                              >
                              <ul class="list-unstyled h6 mb-0 text-warning">
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                                <li class="list-inline-item mb-0">
                                  <i class="mdi mdi-star"></i>
                                </li>
                              </ul>
                            </h5>
                            <ul class="list-unstyled head mb-0">
                              <li
                                v-for="(_item, _index) in item.job_type"
                                :key="_index + '15'"
                                class="badge badge-success badge-pill"
                              >
                                {{ _item }}
                              </li>
                            </ul>
                          </div>

                          <div class="card-body content position-relative">
                            <div
                              class="
                                firm-logo
                                rounded-circle
                                bg-light
                                text-center
                              "
                            >
                              <img
                                :src="mediaUrl + item.recruiter.profile_pic"
                                class="avatar avatar-md-sm"
                                alt=""
                              />
                            </div>
                            <div class="company-detail text-center mt-3">
                              <h5 class="mb-0">
                                <router-link
                                  :to="
                                    '/employer/' +
                                    item.recruiter.company_name
                                      .toLowerCase()
                                      .replace(/ /g, '-')
                                      .replace(/[^\w-]+/g, '')
                                  "
                                  class="text-dark company-name"
                                  >{{
                                    item.recruiter.company_name
                                  }}</router-link
                                >
                              </h5>
                              <p class="text-muted">
                                <a
                                  @click="goToCity(_item)"
                                  class="video-play-icon text-muted"
                                  v-for="(_item, _index) in item.location_city"
                                  :key="_index + '16'"
                                  style="cursor: pointer"
                                >
                                  <map-pin-icon
                                    class="fea icon-sm"
                                  ></map-pin-icon>
                                  {{ _item }}
                                </a>
                              </p>
                            </div>

                            <div style="display: flex">
                              <ul
                                class="job-facts list-unstyled"
                                style="
                                  width: 65%;
                                  display: flex;
                                  flex-direction: column;
                                "
                              >
                                <li class="list-inline-item text-muted">
                                  <check-icon
                                    class="fea icon-sm text-success mr-1"
                                  ></check-icon>
                                  {{ item.minimum_experience_years + " years" }}
                                </li>
                                <li class="list-inline-item text-muted">
                                  <check-icon
                                    class="fea icon-sm text-success mr-1"
                                  ></check-icon>
                                  {{ item.job_function }}
                                </li>
                              </ul>
                              <ul
                                class="list-unstyled social-icon mb-0 mt-4"
                                style="width: 35%"
                              >
                                <ShareNetwork
                                  network="twitter"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                  twitterUser="youyuxi"
                                >
                                  <li
                                    class="list-inline-item ml-1"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <twitter-icon
                                        class="fea icon-xs fea-social"
                                      ></twitter-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>

                                <ShareNetwork
                                  network="facebook"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                >
                                  <li
                                    class="list-inline-item"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <facebook-icon
                                        class="fea icon-sm fea-social"
                                      ></facebook-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>
                                <ShareNetwork
                                  network="linkedin"
                                  url="https://news.vuejs.org/issues/180"
                                  title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                  description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                  quote="The hot reload is so fast it\'s near instant. - Evan You"
                                  hashtags="job4u_pk"
                                >
                                  <li
                                    class="list-inline-item ml-1"
                                    style="margin-right: 4%"
                                  >
                                    <a
                                      href="javascript:void(0)"
                                      class="rounded"
                                    >
                                      <linkedin-icon
                                        class="fea icon-sm fea-social"
                                      ></linkedin-icon>
                                    </a>
                                  </li>
                                </ShareNetwork>
                              </ul>
                            </div>
                            <button
                              v-on:click="singleDetail(item)"
                              class="btn btn-outline-primary btn-block"
                            >
                              View Details
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="col-lg-12 pl-0 pr-0 sidbar_ch_new" v-if="detail">
                    <div class="card sidebar rounded border-0 rounded-0">
                      <div
                        class="card-body widget border-bottom cardbodych"
                        style=""
                      >
                        <h5 class="mb-0 bold position_style_ch border-0">
                          {{ detail.job_title }}
                          <!-- <span class="application_count"
                            >(Applicants:
                            {{ detail.job_application_count }})</span
                          > -->
                          <p
                            class="company-name mb-0"
                            style="font-size: 15px !important"
                          >
                            {{ detail.recruiter.company_name }}
                            <span>
                              <img
                                src="images/job_page/Verified.svg"
                                alt="..."
                              />
                            </span>
                          </p>
                        </h5>
                        <button
                          class="badge badge-danger badge-pill"
                          @click="detail = false"
                        >
                          X
                        </button>
                      </div>

                      <div
                        class="
                          job_details_btn
                          pl-2
                          pr-2
                          pt-2
                          pb-0
                          border-bottom
                        "
                      >
                        <router-link
                          v-if="auth && !applied[detail.id]"
                          :to="
                            '/jobs-careers/apply/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '') +'/' + detail.id
                          "
                          class="m-1"
                          ><img src="/images/job_page/Apply.png" alt="..."
                        /></router-link>
                        <router-link v-if="!auth" :to="'/login'" class="m-1"
                          ><img src="/images/job_page/Apply.png" alt="..."
                        /></router-link>
                        <!-- <router-link
                          v-if="auth && applied[detail.id]"
                          :to="
                            '/jobs-careers/apply/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="m-1"
                          ><img src="/images/job_page/Applied.png" alt="..."
                        /></router-link> -->
                        <button
                          type="button"
                          v-if="auth && applied[detail.id]"
                          @click="saveJob(detail)"
                          class="m-1"
                        >
                          <img src="/images/job_page/Applied.png" alt="..." />
                        </button>
                        <!-- <router-link
                          :to="
                            '/jobs-careers/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="btn btn-sm sidebar_btns btn_deteil_tns m-1"
                          >View Details</router-link
                        > -->

                        <button
                          type="button"
                          v-if="auth && !saveJobSuccess[detail.id]"
                          @click="saveJob(detail)"
                          class="m-1"
                        >
                          <img src="/images/job_page/Save.png" alt="..." />
                        </button>
                        <router-link v-if="!auth" :to="'/login'" class="m-1"
                          ><img src="/images/job_page/Save.png" alt="..."
                        /></router-link>
                        <button
                          type="button"
                          v-if="auth && saveJobSuccess[detail.id]"
                          class="m-1"
                        >
                          <img src="/images/job_page/Saved.png" alt="..." />
                        </button>
                        <router-link v-if="!auth" :to="'/login'" class="m-1"
                          ><img
                            src="images/job_page/Invite_friends.png"
                            alt="..."
                        /></router-link>
                        <router-link v-if="auth" class="m-1"
                          ><img
                            src="images/job_page/Invite_friends.png"
                            alt="..."
                        /></router-link>

                        <!-- <button
                          type="button"
                          @click="detail = false"
                          class="btn btn-sm sidebar_btns_cls btn_deteil_tns m-1"
                        >
                          Close
                        </button> -->
                        <div class="icons">
                          <ul class="list-unstyled head mb-0">
                            <ul class="list-unstyled social-icon">
                              <!-- style="text-align: right !important" -->
                              <ShareNetwork
                                network="twitter"
                                url="https://news.vuejs.org/issues/180"
                                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                quote="The hot reload is so fast it\'s near instant. - Evan You"
                                hashtags="job4u_pk"
                                twitterUser="youyuxi"
                              >
                                <li
                                  class="list-inline-item ml-1"
                                  style="margin-right: 0%"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="rounded"
                                    style="border: none"
                                    title="Share on Twitter"
                                  >
                                    <twitter-icon
                                      class="fea icon-xs fea-social"
                                    ></twitter-icon>
                                  </a>
                                </li>
                              </ShareNetwork>

                              <ShareNetwork
                                network="facebook"
                                url="https://news.vuejs.org/issues/180"
                                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                quote="The hot reload is so fast it\'s near instant. - Evan You"
                                hashtags="job4u_pk"
                              >
                                <li
                                  class="list-inline-item"
                                  style="margin-right: 0%"
                                >
                                  <a
                                    href="javascript:void(0)"
                                    class="rounded"
                                    style="border: none"
                                    title="Share on Facebook"
                                  >
                                    <facebook-icon
                                      class="fea icon-sm fea-social"
                                    ></facebook-icon>
                                  </a>
                                </li>
                              </ShareNetwork>
                              <ShareNetwork
                                network="linkedin"
                                url="https://news.vuejs.org/issues/180"
                                title="Say hi to Vite! A brand new, extremely fast development setup for Vue."
                                description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
                                quote="The hot reload is so fast it\'s near instant. - Evan You"
                                hashtags="job4u_pk"
                              >
                                <li class="list-inline-item">
                                  <a
                                    href="javascript:void(0)"
                                    class="rounded"
                                    style="border: none"
                                    title="Share on LinkedIn"
                                  >
                                    <linkedin-icon
                                      class="fea icon-sm fea-social"
                                    ></linkedin-icon>
                                  </a>
                                </li>
                              </ShareNetwork>
                            </ul>
                          </ul>
                        </div>
                      </div>

                      <div
                        class="card-body cardbodych2 pt-0"
                        style="overflow: auto"
                      >
                        <div class="row" style="margin-top: 1%">
                          <div class="border-bottom pl-4 pr-4 pb-2">
                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center"
                                  style="margin-top: 5%"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Time_shift.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Job shift</span
                                      >
                                    </h4>
                                    <p
                                      class="text-primary mb-0 detail_body"
                                      v-for="(_item, _index) in detail &&
                                      detail.job_shift"
                                      :key="_index + '17'"
                                    >
                                      <span
                                        v-if="
                                          _index > 0 &&
                                          _index < detail.job_shift.length
                                        "
                                        style=""
                                        >,</span
                                      >
                                      {{ _item }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center"
                                  style="margin-top: 5%"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Time_shift.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Employment type</span
                                      >
                                    </h4>
                                    <span
                                      class="text-primary mb-0 detail_body"
                                      v-for="(_item, _index) in detail &&
                                      detail.job_type"
                                      :key="_index + '18'"
                                    >
                                      {{ _item }}
                                    </span>
                                  </div>
                                </div>
                                <!-- <div class="media widget align-items-center mt-3">
                              <div class="media-body d-flex">
                                <h4
                                  class="widget-title mb-0 bold detail_heading"
                                >
                                  <img
                                    class="pr-2"
                                    src="images/job_page/Experience.png"
                                    alt="..."
                                  />
                                </h4>
                                <p class="text-primary mb-0 detail_body">
                                  {{
                                    detail &&
                                    detail.minimum_experience_years + " years"
                                  }}
                                </p>
                              </div>
                            </div> -->
                              </div>
                            </div>

                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Money_left.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Per month salary</span
                                      >
                                    </h4>
                                    <p class="text-primary mb-0 detail_body">
                                      +{{ detail && detail.salary_min }} to
                                      {{ detail && detail.salary_max }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Sector.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Job function</span
                                      >
                                    </h4>
                                    <p class="text-primary mb-0 detail_body">
                                      {{ detail && detail.job_function }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center"
                                  style="margin-top: 5%"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Applicants.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Total applicants to date</span
                                      >
                                    </h4>
                                    <p class="text-primary mb-0 detail_body">
                                      <span
                                        v-if="detail.job_application_count < 50"
                                      >
                                        Less than 50
                                      </span>
                                      <span
                                        v-if="detail.job_application_count > 50"
                                      >
                                        More than 50
                                      </span>
                                      <!-- {{ detail.job_application_count }} -->
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center"
                                  style="margin-top: 5%"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/No._of_Vacancies.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Number of vacancies</span
                                      >
                                    </h4>
                                    <span class="text-primary mb-0 detail_body">
                                      {{ detail.no_of_vacancies }} Vacancies
                                    </span>
                                  </div>
                                </div>
                                <!-- <div class="media widget align-items-center mt-3">
                              <div class="media-body d-flex">
                                <h4
                                  class="widget-title mb-0 bold detail_heading"
                                >
                                  <img
                                    class="pr-2"
                                    src="images/job_page/Experience.png"
                                    alt="..."
                                  />
                                </h4>
                                <p class="text-primary mb-0 detail_body">
                                  {{
                                    detail &&
                                    detail.minimum_experience_years + " years"
                                  }}
                                </p>
                              </div>
                            </div> -->
                              </div>
                            </div>

                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Education_required.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Minimum qualification</span
                                      >
                                    </h4>
                                    <p class="text-primary mb-0 detail_body">
                                      {{ detail && detail.education }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Gender_preference.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Gender preference</span
                                      >
                                    </h4>
                                    <p
                                      class="text-primary mb-0 detail_body"
                                      v-for="(_item, _index) in detail &&
                                      detail.gender"
                                      :key="_index + '19'"
                                    >
                                      <span
                                        v-if="
                                          _index > 0 &&
                                          _index < detail.gender.length
                                        "
                                        style=""
                                        >/</span
                                      >{{ _item }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading'
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Date_posted.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Job post date</span
                                      >
                                    </h4>
                                    <p
                                      class="text-primary mb-0 mb-0 detail_body"
                                    >
                                      {{
                                        moment(detail.created_at).format(
                                          "MMMM Do YYYY"
                                        )
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>

                              <div class="col col-md-6">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div class="media-body d-flex">
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Due_date.svg"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Application deadline</span
                                      >
                                    </h4>
                                    <p
                                      class="text-primary mb-0 mb-0 detail_body"
                                    >
                                      {{
                                        moment(detail.apply_by).format(
                                          "MMMM Do YYYY"
                                        )
                                      }}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="row" style="margin-top: 1%">
                              <div class="col col-md-12">
                                <div
                                  class="media widget align-items-center mt-3"
                                >
                                  <div
                                    class="media-body location_ch"
                                    style="display: -webkit-box"
                                  >
                                    <h4
                                      class="
                                        widget-title
                                        mb-0
                                        bold
                                        detail_heading
                                        tooltipch
                                      "
                                    >
                                      <img
                                        class="pr-2"
                                        src="images/job_page/Location_left.png"
                                        alt="..."
                                      />
                                      <span class="tooltiptextch"
                                        >Job locations</span
                                      >
                                    </h4>
                                    <div class="row">
                                      <p
                                        class="
                                          col col-md-3
                                          text-primary
                                          mb-0
                                          detail_body
                                        "
                                        style="display: contents"
                                      >
                                        <span
                                          v-for="(_item, _index) in detail &&
                                          detail.location_city"
                                          :key="_index + '20'"
                                          style="display: inline-flex"
                                        >
                                          <span
                                            v-if="
                                              _index > 0 &&
                                              _index <
                                                detail.location_city.length
                                            "
                                            style=""
                                            >&nbsp;,&nbsp;</span
                                          >
                                          {{ _item }}
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col col-md-12">
                            <div class="media widget align-items-center mt-3">
                              <div class="media-body">
                                <h4
                                  class="widget-title mb-0 bold detail_heading"
                                >
                                  Job Description
                                </h4>
                                <p
                                  id="temp"
                                  class="
                                    para-desc
                                    mx-auto
                                    text-muted
                                    detail_body
                                  "
                                  v-html="detail.job_description"
                                ></p>
                                <!-- <p id="temp1" class="text-primary mb-0 mb-0"></p> -->
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- <router-link
                          v-if="auth && !applied[detail.id]"
                          :to="
                            '/jobs-careers/apply/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="btn btn-sm sidebar_btns"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                          >Apply Now <i class="mdi mdi-send text-white"></i
                        ></router-link>
                        <router-link
                          v-if="auth && applied[detail.id]"
                          :to="
                            '/jobs-careers/apply/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="btn btn-sm sidebar_btns_ch"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                          >Applied<i class="mdi mdi-send text-white"></i
                        ></router-link>

                        <router-link
                          :to="
                            '/jobs-careers/pakistan/' +
                            detail.location_city
                              .map((v) => v.toLowerCase())
                              .join('-') +
                            '/' +
                            detail.job_title
                              .toLowerCase()
                              .replace(/ /g, '-')
                              .replace(/[^\w-]+/g, '')
                          "
                          class="btn btn-sm sidebar_btns"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                          >View Details</router-link
                        >

                        <button
                          type="button"
                          v-if="auth && !saveJobSuccess[detail.id]"
                          @click="saveJob(detail)"
                          class="btn btn-sm sidebar_btns"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                        >
                          Save <i class="mdi mdi-save"></i>
                        </button>
                        <button
                          type="button"
                          v-if="auth && saveJobSuccess[detail.id]"
                          class="btn btn-sm sidebar_btns_ch"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                        >
                          Saved <i class="mdi mdi-save"></i>
                        </button>

                        <button
                          type="button"
                          @click="detail = false"
                          class="btn btn-sm sidebar_btns_cls"
                          style="margin-top: 5%; bottom: 40px; margin-left: 2%"
                        >
                          Close
                        </button> -->

                        <!-- <div
                          class="alert alert-success"
                          role="alert"
                          style="margin-top: 10%"
                          v-if="savejobbtnclicked.length > 0"
                        >
                          {{ savejobbtnclicked }}
                        </div> -->
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!-- popup -->

                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <!--end row-->
        <div class="col-md-12 mt-10" style="text-align: center">
          <button
            type="button"
            @click="loadmore"
            class="btn btn_lood_ch"
            v-if="show_load_more"
          >
            Load More Jobs
          </button>
        </div>
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- Job List End -->
    <!--end section-->
    <Footer />
    <!-- Footer End -->
    <Switcher />
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-soft-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
